import { useEffect, useState, useRef } from "react"
import { timeFormat as d3TimeFormat } from "d3-time-format"
import { timeDay as d3TimeDay } from "d3-time"
import { ResizeObserver } from "@juggle/resize-observer"
import betsy_data from "./betsy_data.json"

export const callAccessor = (accessor, d, i) => (
  typeof accessor === "function" ? accessor(d, i) : accessor
)

export const combineChartDimensions = (dimensions) => {
  const parsedDimensions = {
    marginTop: 40,
    marginRight: 30,
    marginBottom: 40,
    marginLeft: 75,
    ...dimensions
  }

  return {
    ...parsedDimensions,
    boundedHeight: Math.max(parsedDimensions.height - parsedDimensions.marginTop - parsedDimensions.marginBottom, 0),
    boundedWidth: Math.max(parsedDimensions.width - parsedDimensions.marginLeft - parsedDimensions.marginRight, 0),
  }
}

export const useChartDimensions = (passedSettings) => {
  const ref = useRef()
  const dimensions = combineChartDimensions(passedSettings)

  const [width, changeWidth] = useState(0)
  const [height, changeHeight] = useState(0)

  // this caused a "returned fewer hooks than expected error"
  // if (dimensions.width && dimensions.height) return [ref, dimensions]


  useEffect(() => {
    // this was causing an error with a hook returning
    // if (dimensions.width && dimensions.height) return [ref, dimensions]

    const element = ref.current
    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries)) return 
      if (!entries.length) return 

      const entry = entries[0]

      if (width !== entry.contentRect.width) changeWidth(entry.contentRect.width)
      if (height !== entry.contentRect.height) changeHeight(entry.contentRect.height)
    })

    resizeObserver.observe(element)

    return () => resizeObserver.unobserve(element)
  }, [passedSettings, height, width, dimensions])

  // don't actually need this if newSettings is handling that business
  // if (dimensions.width && dimensions.height) return [ref, dimensions]

  const newSettings = combineChartDimensions({
    ...dimensions,
    width: dimensions.width || width,
    height: dimensions.height || height
  })

  return [ref, newSettings]
}

let lastId = 0
export const useUniqueId = (prefix = "") => {
  lastId++
  return [prefix, lastId].join("-")
}

const boxMullerRandom = () => (
  Math.sqrt(-2.0 * Math.log(Math.random())) *
  Math.cos(2.0 * Math.PI * Math.random())
)
const randomAroundMean = (mean, deviation) => mean + boxMullerRandom() * deviation

const today = new Date()
const twoDaysFromNow = d3TimeDay.offset(today, 2)
const formatDate = d3TimeFormat("%m/%d/%Y")

export const getTimelineData = (length = 100) => {
  // get a random date around mean 70 and std of 20
  let lastTemperature = randomAroundMean(70, 20)
  // start from a date 100 days in the past
  const firstTemperature = d3TimeDay.offset(twoDaysFromNow, -length)

  return new Array(length).fill(0).map((d, i) => {
    // move the temperature a little bit in a certain direction
    lastTemperature += randomAroundMean(0, 2)
    return {
      // get the date i days away from the firstTemperature date
      date: formatDate(d3TimeDay.offset(firstTemperature, i)),
      temperature: lastTemperature
    }
  })
}

export const getScatterData = (count = 100) => (
  new Array(count).fill(0).map((d, i) => ({
    temperature: randomAroundMean(70, 20),
    humidity: randomAroundMean(0.5, 0.1),
  }))
)


export const getBetsyData = () => {
  let lastScore = 50 + randomAroundMean(0, 1)
  // const firstDay = d3TimeDay.offset(twoDaysFromNow, -length)

  return betsy_data.map(d => {
    lastScore += randomAroundMean(0, 1)

    return {
      date: d.date,
      files: d.files,
      score: lastScore
    }
  })
}
