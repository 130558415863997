import React from "react"
import styled from "styled-components"

const TooltipWrapper = styled.div`
  opacity: 0;
  position: absolute;
  top: -12px;
  left: 0;
  padding: 0.6em 1em;
  background: #fff;
  text-align: center;
  border: 1px solid #ddd;
  z-index: 10;
  transition: all 0.2s ease-out;
  pointer-events: none;
  font-size: 0.8em;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    background: white;
    border: 1px solid #ddd;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: translate(-50%, 50%) rotate(45deg);
    transform-origin: center center;
    z-index: 10;
  }
  ${props => true && `
    transform: translate(calc(-50% + ${props.x}px), calc(-100% + ${props.y}px));
  `}
  ${props => false && `
    transform: translate(${props.x}px, ${props.y}px);
  `}  
  ${props => props.isShowing ? `
    opacity: 1;
  ` : `
    opacity: 0;
  `}
`

const Tooltip = ({ children, x = 0, y = 0, isShowing = false, ...props }) => {
  return (
    <TooltipWrapper x={x} y={y} isShowing={isShowing} {...props}>       
      { children }
    </TooltipWrapper>
  )
}

export default Tooltip