import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ChartContext = React.createContext()
export const useChartDimensions = () => React.useContext(ChartContext)

const StyledSVG = styled.svg`
  overflow: visible;

  & text {
    fill: #95A5A6;
  }

  --x1: 0.1;
  --y1: 0.67;
  --x2: 0.29;
  --y2: 0.98;
  
  --cubic-transition: cubic-bezier(var(--x1), var(--y1), var(--x2), var(--y2)); 


  transition: all 0.6s cubic-bezier(.8, 0, .2, 1);

  ${props => props.rotated && `
    transform: rotate(90deg);
  `}
`

const StyledG = styled.g`

`

const Chart = ({ dimensions, children, ...props }) => {
  return (
    <ChartContext.Provider value={dimensions}>
      <StyledSVG {...props}>
        <StyledG transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}>
          { children }
        </StyledG>
      </StyledSVG>
    </ChartContext.Provider>
  )
}

Chart.propTypes = {
  dimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    marginTop: PropTypes.number,
    marginRight: PropTypes.number,
    marginBottom: PropTypes.number,
    marginLeft: PropTypes.number,
  })
}

Chart.defaultProps = {
  dimensions: {}
}

export default Chart