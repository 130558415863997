import React from "react"
// import Img from "gatsby-image"
import ImageCarousel from "./ImageCarousel"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import {
  scaleLinear as d3ScaleLinear,
  scaleSequential as d3ScaleSequential,
  scaleOrdinal as d3ScaleOrdinal
  // quantile as d3Quantile
} from 'd3-scale'
import {
  // interpolateCool as d3InterpolateCool
  interpolateViridis as d3InterpolateViridis,
  interpolateCubehelixDefault as d3InterpolateHelix,
  interpolateBuGn as d3InterpolateBuGn,
  interpolateBlues as d3InterpolateBlues
} from "d3-scale-chromatic"
import {
  range as d3Range,
  // quantile as d3Quantile,
  min as d3Min,
  max as d3Max,
  // ascending as d3Ascending,
  extent as d3Extent
} from "d3-array"
import { 
  timeFormat as d3TimeFormat,
  timeParse as d3TimeParse
} from 'd3-time-format'
import {
  timeDay as d3TimeDay,
  timeDays as d3TimeDays,
  timeWeek as d3TimeWeek,
  timeMonth as d3TimeMonth,
  timeYear as d3TimeYear,
  utcYear as d3UTCYear,
} from 'd3-time'

import Chart from "./Chart/Chart"
import Tooltip from "./Chart/Tooltip"
import { 
  useChartDimensions, 
  callAccessor, 
  getBetsyData,
  // getTimelineData 
} from "./utils"
import styled from "styled-components"

const HeatmapDashboardWrapper = styled.div`
  padding: 1em 3em;
  background: #F1F3F5;
  min-height: 100vh;

  & > h1 {
    text-align: center;
  }
`

const PureHeatmapDashboard = ({ gatsbyData }) => {
  const vidEdges = gatsbyData && gatsbyData.allFile && gatsbyData.allFile.edges
  const imgEdges = gatsbyData && gatsbyData.allImageSharp && gatsbyData.allImageSharp.edges
  const allEdges = vidEdges.concat(imgEdges)
  // console.log(imgEdges)
  const [data, setData] = React.useState(getBetsyData(700))
  const [selectedDate, selectDate] = React.useState(null)
  const [currentIndex, setCurrentIndex] = React.useState(0)

  // console.log(selectedDate)

  // const newData = data.map(d => ({ date: d.date, score: d.temperature }))

  const handleSelectDate = (d) => {
    setCurrentIndex(0)
    selectDate(d)
  }

  return (
    <HeatmapDashboardWrapper>
      <h1>a year in the life of betsy</h1>
      <br />
      <br />
      <Heatmap 
        data={data} 
        redraw={() => setData(getBetsyData(700))}
        selectDate={handleSelectDate}
      />
      <ImageCarousel
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        setSelectedDate={selectDate}
        selectedDate={selectedDate}
        data={data}
        edges={allEdges}
        // edges={imgEdges}
        // videoEdges={vidEdges}
      />
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        width: "100%"
      }}>
        <p>Made with ❤️ by Jeff and Greg Astor</p>
        <p>Contributions from the rest of the family and Insta.</p>
      </div>
    </HeatmapDashboardWrapper>
  )
}

// UTILITY FUNCTIONS
const getDayNumber = d => d3TimeDay.count(d3UTCYear(d), d)
const getMonthNumber = d => d3TimeMonth.count(d3UTCYear(d), d)
const getWeekNumber = d => d3TimeWeek.count(d3UTCYear(d), d)
const getWeekdayNumber = d => d.getUTCDay()
const getYearNumber = d => d.getUTCFullYear()
const formatDay = d => ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][d.getUTCDay() ]
const formatMonth = d => [
  "Jan", "Feb", "Mar", 
  "Apr", "May", "Jun", 
  "Jul", "Aug", "Sep", 
  "Oct", "Nov", "Dec"
][getMonthNumber(d) - 1]
// const formatDate = d3TimeFormat("%m/%d/%Y")
const formatDate = d3TimeFormat("%m-%d-%Y")
const tooltipFormatDate = d3TimeFormat("%a - %B %-d, %Y")

const timeParser = d3TimeParse("%m-%d-%Y")

/**
 * Draw heatmap depending on the type of view
 * 
 * viewtypes: ["lifetime", "year", "month", "week", "day"]
 * 
 */
const filterHeatMapData = (data, viewType ) => {
  if (viewType === "lifetime") return data

  if (viewType === "year") {
    const betsysDay = timeParser("03-28-2020")
    // const betsysDay = new Date(2020, 03, 27)
    // get all the dates between last year and today
    // const yesterday = d3TimeDay.offset(new Date, -1)
    const yesterday = d3TimeDay.offset(betsysDay, -1)
    // const nowish = d3TimeDay.offset(new Date, 0)
    const nowish = d3TimeDay.offset(betsysDay, 0)
    const lastYear = d3TimeYear.offset(nowish, -1)
    // const lastYearMinusTwo = d3TimeDay.offset(lastYear, -2)
    // let lastYearsDates = d3TimeDays(lastYear, new Date)
    let lastYearsDates = d3TimeDays(lastYear, betsysDay)
    // const extraTwoDays = d3TimeDays(yesterday, d3TimeDay.offset(new Date, 1))
    const extraTwoDays = d3TimeDays(yesterday, d3TimeDay.offset(betsysDay, 1))
    // console.log(extraTwoDays)
    lastYearsDates = [...lastYearsDates, ...extraTwoDays]

    // need to get values to offset by
    const firstDate = lastYearsDates[0]
    const firstDayOfMonth = d3TimeMonth.floor(firstDate)

    const createDateValuesObject = d => ({
        date: d,
        dateLabel: formatDate(d),
        dayLabel: formatDay(d),
        monthLabel: formatMonth(d),
        dayNumber: getDayNumber(d),
        weekNumber: getWeekNumber(d),
        monthNumber: getMonthNumber(d),
        weekDayNumber: getWeekdayNumber(d),
        yearNumber: getYearNumber(d)     
    })

    const dateValues = lastYearsDates.reduce((acc, d, i) => {
      acc[formatDate(d)] = createDateValuesObject(d)
      return acc
    }, {})


    const dateLabels = Object.keys(dateValues)
    let finalData = data.filter(d => dateLabels.includes(d.date))
      .map(d => ({
        score: d.score + (d.files.length * 150),
        files: d.files,
        ...dateValues[d.date]
      }))

    // add any days that are missing from the first month
    const missingDates = d3TimeDays(firstDayOfMonth, firstDate)
    const missingData = missingDates.map(date => ({
      score: 0,
      files: [],
      ...createDateValuesObject(date)
    }))

    
    return [...missingData, ...finalData]
      
  }

  // if (viewType === "month") {
  //   const lastMonth = d3TimeMonth.offset(new Date, -1)
  // }
}

const HeatmapWrapper = styled.div`
  position: relative;
  min-height: ${props => props.dimensions.height}px;
  height: ${props => props.dimensions.height}px;
  ${props => props.width && `
    width: ${props.dimensions.width}px;  
  `}
`

const Heatmap = ({ 
  data,
  redraw,
  settings,
  viewType,
  selectDate,
   ...props 
}) => {
  // console.log(data)
  const {
    height,
    width,
    cellSize,
    cellGutter,
  } = settings

  // data
  const relevantData = filterHeatMapData(data, viewType)
  const [currentMonthFilter, setCurrentMonthFilter] = React.useState(null)

  if (typeof window !== "undefined") {
    // console.log(relevantData)  
  }

  // dimensions
  const [ref, dimensions] = useChartDimensions({ height, width })

  // accessors
  const dateAccessor = d => d.date
  // const dayNumberAccessor = d => d.dayNumber
  const monthAccessor = d => d.monthNumber
  const monthLabelAccessor = d => d.monthLabel
  // const weekAccessor = d => d.weekNumber
  // const weekdayAccessor = d => d.weekDayNumber
  const yearAccessor = d => d.yearNumber
  const metricAccessor = d => d.score

  // x accessor
  const minDate = d3Min(relevantData, dateAccessor)
  const firstWeekOfMonthOfMinDate = d3TimeMonth.floor(minDate)
  const minYear = d3Min(relevantData, yearAccessor)
  const xAccessor = (d, i) => {
    const weekOffset = d3TimeWeek.count(firstWeekOfMonthOfMinDate, dateAccessor(d))
    const monthNumber = monthAccessor(d)
    const yearNumber = yearAccessor(d) - minYear
    const monthYearOffset = monthNumber + yearNumber * 12
    return weekOffset + monthYearOffset * cellGutter
   } 

  // y accessor
  const yAccessor = d => d.weekDayNumber * cellSize + cellGutter

  // scales
  const xScale = d3ScaleLinear()
    .domain(d3Extent(relevantData, xAccessor))
    .range([0, dimensions.boundedWidth])
  const yScale = d3ScaleLinear()
    .domain(d3Extent(relevantData, yAccessor))
    .range([0, dimensions.boundedHeight])

  // get unique months label scales
  let monthLabels = Array.from(new Set(relevantData.map(d => monthLabelAccessor(d))))
  monthLabels = [...monthLabels, monthLabels[0]] // add the first month at the end again
  const monthScale = d3ScaleLinear()
    .domain([0, monthLabels.length])
    .range([0, dimensions.boundedWidth])
  
  // get unique weekday labels
  const weekdayLabels = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

  // min max values for colors
  const max = d3Max(relevantData, metricAccessor)
  const min = d3Min(relevantData, metricAccessor)
  // const colors = d3ScaleSequential(d3InterpolateBlues)
  // const colors = d3ScaleSequential(d3InterpolateBuGn)
  // const colors = d3ScaleSequential(d3InterpolateHelix)
  const colors = d3ScaleSequential(d3InterpolateViridis)
  
  const colorScale = d3ScaleLinear()
    .domain([min, 0.5, max])
    // .range([colors(0.3), colors(0.7), colors(1)])
    .range([colors(1), colors(0.7), colors(0)])

  // scaled accessors
  const scaledXAccessor = (d, i) => xScale(xAccessor(d, i))
  const scaledYAccessor = (d, i) => yScale(yAccessor(d, i))
  const scaledColorAccessor = d => colorScale(metricAccessor(d))
  const scaledMonthXAccessor = (d, i) => (monthScale(i) * .5) + (cellSize * 2) + (i % 12)
  const scaledWeekdayYAccessor = (d, i) => i * (cellSize + 3.75) + cellGutter


  // peripherals and interactions
  const [tooltipShowing, setTooltipShowing] = React.useState(false)
  const [tooltipLocation, setTooltipLocation] = React.useState({x: 0, y: 0})
  const [tooltipData, setTooltipData] = React.useState({})
  const renderToolTip = () => {
    // console.log(tooltipData)
    return (
      <div>
        { tooltipData.date && <p>{tooltipFormatDate(tooltipData.date)}</p> }
        { 
          tooltipData.files 
          && <p>{tooltipData.files.length} {tooltipData.files.length === 1 ? "Picture" : "Pictures"}</p> }
      </div>
    )
  }

  const handleDayHover = (d, i) => {
    const score = callAccessor(metricAccessor, d, i)
    const x = callAccessor(scaledXAccessor, d, i) + dimensions.marginLeft
    const y = callAccessor(scaledYAccessor, d, i) + dimensions.marginTop
    // console.log(x, y)
    
    if (score > 0) {
      setTooltipData(d)
      setTooltipLocation({ x, y })
      setTooltipShowing(true)
    }
  }

  const handleDayLeave = () => {
    setTooltipLocation({ x: 0, y: 0})
    setTooltipShowing(false)
  }

  // handle hover over one month
  const handleMonthHover = (label, i) => {
    // console.log(`hover: ${label} ${i}`)
    setCurrentMonthFilter(label)
  }

  const handleMonthLeave = () => {
    setCurrentMonthFilter(null)
  }

  return (
    <HeatmapWrapper dimensions={{height, width}} {...props} ref={ref}>
      <Tooltip 
        isShowing={tooltipShowing} 
        x={tooltipLocation.x} 
        y={tooltipLocation.y}
      >
        { renderToolTip() }
      </Tooltip> 
      <Chart dimensions={dimensions}>       
        {weekdayLabels.map((label, i) => (
          <WeekdayLabel
            i={i}
            key={label}
            label={label}
            x={-30}
            y={callAccessor(scaledWeekdayYAccessor, label, i)}
          />
        ))}
        {monthLabels.map((label, i) => (
          <MonthLabel
            key={i}
            label={label}
            onMouseEnter={() => handleMonthHover(label, i)}
            onMouseLeave={() => handleMonthLeave()}
            x={callAccessor(scaledMonthXAccessor, label, i)}
            y={-20}
          />
        ))}
        {relevantData.map((d, i) => (
          <HeatmapDay
            max={max}
            key={dateAccessor(d)}
            date={dateAccessor(d)}
            monthLabel={callAccessor(monthLabelAccessor, d, i)}
            onMouseEnter={() => handleDayHover(d, i)}
            onMouseLeave={() => handleDayLeave()}
            color={callAccessor(scaledColorAccessor, d, i)}
            score={callAccessor(metricAccessor, d, i)}
            currentMonthFilter={currentMonthFilter}
            x={callAccessor(scaledXAccessor, d, i)}
            y={callAccessor(scaledYAccessor, d, i)}
            width={cellSize}
            height={cellSize}
            selectDate={selectDate}
          />
        ))}
      </Chart>
    </HeatmapWrapper>
  )
}

Heatmap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    score: PropTypes.number,
    desc: PropTypes.string,
    label: PropTypes.string,    
  })),
  settings: PropTypes.shape({
    gutter: PropTypes.number,
    item_gutter: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    item_size: PropTypes.number,
    label_padding: PropTypes.number,
    max_block_height: PropTypes.number,
    transition_duration: PropTypes.number,
    tooltip_width: PropTypes.number,
    tooltip_padding: PropTypes.number,
  }),
  viewType: PropTypes.oneOf(["lifetime", "year", "month", "week", "day"]),
}

Heatmap.defaultProps = {
  data: [],
  settings: {
    gutter: 5,
    cellGutter: 1,
    // width: 1200,
    height: 300,
    cellSize: 15,
    labelPadding: 40,
    max_block_height: 20,
    transition_duration: 500,
    tooltip_width: 250,
    tooltip_padding: 15,
  },
  viewType: "year"
}


const StyledHeatmapDay = styled.circle.attrs(props => ({
  style: {
    opacity: props.opacity,
    transition: `all ${Math.cos(Math.PI * Math.random()) + 1.5}s ease`
  }
}))`
  cursor: ${props => props.score > 0 ? "pointer" : ""};
`

const HeatmapDay = ({
  x,
  y,
  r,
  max,
  date,
  monthLabel,
  alpha,
  score,
  color,
  value,
  width,
  height,
  selectDate,
  currentMonthFilter,
  ...props
}) => {
  const ratio = Math.abs(score) / max
  const size = (width * 0.75 + (width * score / max) * .25) / 1.75

  const opacity = .5 + ratio / 2
  
  const getOpacity = () => {
    if (currentMonthFilter) {
      if (currentMonthFilter === monthLabel) {
        return opacity
      }

      return 0.3
    }

    return opacity
  }

  return (
    <StyledHeatmapDay 
      cx={x}
      cy={y}
      // r={size > 0 ? size : 5}
      r={Math.abs(size)}
      score={score}
      opacity={getOpacity()}
      style={{fill: color}}
      onClick={() => selectDate(date)}
      {...props} 
    />
  )
}


const WeekdayLabelText = styled.text.attrs(props => ({
  style: {
    transform: `translate(${props.x}px, ${props.y}px)`
  }
}))`
  font-size: 1em;
  text-anchor: left;
`

const WeekdayLabel = ({ label, x, y, i, ...props }) => {
  // console.log(label, x, y, i)
  return (
    <WeekdayLabelText x={x} y={y} {...props}>
      {label}
    </WeekdayLabelText>
  )
}



const MonthLabelText = styled.text.attrs(props => ({
  style: {
    transform: `translate(${props.x}px, ${props.y}px)`
  }
}))`
  font-size: 1.25em;
  text-anchor: middle;
  alignment-baseline: middle;
`

const MonthLabel = ({ label, x, y, ...props }) => {
  return (
    <MonthLabelText x={x} y={y} {...props}>
      { label }
    </MonthLabelText>
  )
}


const HeatmapDashboard = (props) => {
  return (
    <StaticQuery 
      query={graphql`
        query BetsyQuery {
          allFile(filter: {internal: {mediaType: {eq: "video/quicktime"}}}) {
            edges {
              node {
                internal {
                  mediaType
                  description
                }
                base 
                name
                relativePath
                publicURL
                prettySize
                accessTime
                absolutePath
                sourceInstanceName
                relativeDirectory
              }
            }
          }       
          allImageSharp {
            totalCount
            edges {
              node {
                fixed {
                  originalName
                  # src
                  # srcSet
                  # height
                  ...GatsbyImageSharpFixed
                }
                fluid {
                  # src
                  # sizes
                  # originalImg
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }            
      `}
      render={gatsbyData => (
        <PureHeatmapDashboard
          gatsbyData={gatsbyData}
          {...props}
        />
      )}
    />
  )
}


export default HeatmapDashboard