import React from "react"
import styled from "styled-components"

const VideoPlayerContainer = styled.div``

const VideoPlayer = ({ src }) => {
  // const [videoSrc, setVideoSrc] = React.useState(null)

  // React.useEffect(() => {
  //   setVideoSrc(src)

  //   return () => setVideoSrc(null)
  // }, [src])

  // need to add a key to the video element, otherwise it won't update when the src changes
  // see https://stackoverflow.com/questions/41303012/updating-source-url-on-html5-video-with-react
  return (
    <VideoPlayerContainer>
      {
        src && (
          <video controls autoPlay key={src}>
              <source src={src} type="video/mp4" />
              {/* <source src="D:/mov1.mov" type="video/mp4"></source> */}
              {/* <source src={src} type="video/mp4" /> */}

          </video>          
        )
      }

      {/* <video width="320" height="240" src="mov1.mov"></video> */}
    </VideoPlayerContainer>
  )
}

export default VideoPlayer