import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import VideoPlayer from "../VideoPlayer/VideoPlayer"
import elevation from '../../utils/elevation'

import { timeFormat as d3TimeFormat } from 'd3-time-format'

/*******
 * 
 * Took this one out
 * 

  {
    "name": "betsy_tongue_out_01_17_2019.mov",
    "caption": "Betsy doing betsy things.",
    "isVideo": true
  }

  Took this one out too

  {
      "name": "betsy_doin_weird_shit.JPG",
      "caption": "Betsy up to her old antics again."
  },  

 * 
 * 
 * 
 * 
 */


const ImageCarouselWrapper = styled.div`
  opacity: 0;
  width: 95vw;
  min-height: 150vh;

  display: flex;
  justify-content: center;
  transition: all 0.3s ease;

  ${props => props.isShowing && `
    opacity: 1;
  `}
`

const ImageCarouselMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  flex: 1;
  width: 80vw;
  max-width: 80vw;
`
const ImageCarouselMainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  aling-items: center;
  transition: all 0.3s cubic-bezier(.51,.92,.24,1.15);
  transform: translateX(-100vw);
  width: 70vw;
  max-width: 70vw;
  height: 100px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 10px 30px;
    transform: translateY(-5px);

    & > h4 {
      text-transform: lowercase;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;
    }
  }

  ${props => props.isShowing && `
    transform: translateX(0);
  `}  
`
const ImageCarouselMainCenter = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(250, 250, 250, 0);
  height: 70vh;
  width: 70vw;
  flex: 1;
  margin-top: 20px;

  transition: all 0.3s cubic-bezier(.51,.92,.24,1.15);
  transform: translateX(-100vw);  
  transition-delay: 0.2s;

  ${props => props.isShowing && `
    transform: translateX(0);
  `}   

  & picture img {
    padding: 25px;
    background: white;   
  }
  & .gatsby-image-wrapper {
    box-shadow: ${elevation[1]} !important;
    width: 100%;
  }
`

const ImageCarouselLeft = styled.div`
  & i {
    cursor: pointer;
    font-size: 3em;   
    transform: translateY(-0px); 
    ${props => props.disabled ? `
      color: #ccc;
      cursor: not-allowed;
    ` : `
      color: #000;
    `}
  }
  display: flex;
  justify-content: center;
  align-items: center;  
`
const ImageCarouselRight = styled.div`
  & i {
    cursor: pointer;
    font-size: 3em;
    transform: translateY(-0px);
    ${props => props.disabled ? `
      color: #ccc;
      cursor: not-allowed;
    ` : `
      color: #000;
    `}    
  }
  display: flex;
  justify-content: center;
  align-items: center;
`

const formatDate = d3TimeFormat("%m-%d-%Y")

const ImageCarousel = ({
  currentIndex,
  setCurrentIndex,
  // setSelectedDate,
  selectedDate,
  data,
  edges,
  // videoEdges
}) => {
  // console.log(edges)
  // console.log(videoEdges)

  const getFilteredData = () => {
    if (!selectedDate) return null

    const formattedDate = formatDate(selectedDate)
    
    const filteredData = data.filter(d => d.date === formattedDate)

    return filteredData.length ? filteredData[0] : {}
  }
  
  const filteredData = getFilteredData()

  const isShowing = Boolean(filteredData && filteredData.files && filteredData.files.length)

  // console.log(filteredData)
  // console.log('is showing', isShowing)


  const handleChangeIndex = (num) => {
    if (currentIndex + num < 0) return    
    if (currentIndex + num >= filteredData.files.length) return
    setCurrentIndex(currentIndex+num)
  }

  const pictureAndCaptionData = isShowing ? filteredData.files.map(f => {
    const edge = edges.filter(({ node }) => {
      // if (node.fixed) return node.fixed.originalName === f.name
      if (node.fluid) return node.fluid.originalName === f.name

      return node.base === f.name
    })[0]

    return {
      ...f,
      ...edge.node
    }
  }) : null

  // console.log(pictureAndCaptionData)

  const currentImage = isShowing ? pictureAndCaptionData[currentIndex] : null

  return (
    <ImageCarouselWrapper isShowing={isShowing}>
      <ImageCarouselMain>
        <ImageCarouselMainHeader isShowing={isShowing}>
         <ImageCarouselLeft
           disabled={pictureAndCaptionData && currentIndex === 0}
           onClick={() => handleChangeIndex(-1)}
         >
            <i className="fas fa-angle-left"></i>
          </ImageCarouselLeft>          
          {isShowing 
            && filteredData.files.length 
            && <div><p>{formatDate(selectedDate)}</p><h4>{currentImage.caption}</h4></div>
          }
          <ImageCarouselRight
            disabled={pictureAndCaptionData && currentIndex === pictureAndCaptionData.length - 1}
            onClick={() => handleChangeIndex(1)}
          >
            <i className="fas fa-angle-right"></i>
          </ImageCarouselRight>          
        </ImageCarouselMainHeader>
        <ImageCarouselMainCenter isShowing={isShowing}>            
            {isShowing && (
              currentImage.isVideo 
              ? (
                  <VideoPlayer 
                    src={currentImage.publicURL}
                  />
              ) 
              : ( 
                  // <Img fixed={currentImage.fixed} /> 
                  <Img fluid={currentImage.fluid} />
              ) 
            )}
        </ImageCarouselMainCenter>
      </ImageCarouselMain>
    </ImageCarouselWrapper>
  )
}

export default ImageCarousel